/* New wrapper to override global background */
.page-wrapper {
  background-color: #d3d3d3 !important;
  min-height: 100vh;
}

/* Main container for customer details */
.customer-details-container {
  max-width: 600px;
  margin: 20px auto;
  background: #fff;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.customer-details-container h2 {
  margin-bottom: 1rem;
}

.customer-details-container p {
  margin: 0.5rem 0;
}

/* Ensure page background is light gray */
body {
  background-color: #d3d3d3;
}
