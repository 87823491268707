/* General Container */
.appointment-details-container {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  background: linear-gradient(135deg, #f4f6f8 0%, #e9eff5 100%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Appointment Details Card */
.appointment-details {
  background-color: #fff;
  max-width: 900px;
  margin: 2rem auto;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

/* Title */
.title {
  text-align: center;
  margin-bottom: 2rem;
  color: #333;
  font-size: 2.2rem;
  font-weight: 600;
  border-bottom: 2px solid #ddd;
  padding-bottom: 0.5rem;
}

/* Flex container for left/right sections */
.details-flex {
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
}

/* Left Section: Appointment Details */
.details-grid {
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem 2rem;
}

/* Right Section: Customer Address */
.address-details {
  flex: 1;
  background-color: #fafafa;
  padding: 1.5rem;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
}

/* Detail Items */
.detail-item {
  margin-bottom: 1rem;
}

.detail-label {
  font-weight: 600;
  color: #555;
  margin-bottom: 0.3rem;
  display: block;
}

.detail-value {
  color: #333;
  font-size: 1rem;
}

/* Status Update Section */
.status-update {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.status-label {
  font-weight: 600;
  color: #555;
}

.status-select {
  padding: 0.6rem 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  min-width: 200px;
  transition: border-color 0.3s ease;
}

.status-select:focus {
  border-color: #007bff;
  outline: none;
}

.save-button {
  padding: 0.7rem 1.4rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1rem;
}

.save-button:hover {
  background-color: #0056b3;
}

.save-button:active {
  background-color: #004085;
}

/* Loading State */
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  font-size: 1.5rem;
  color: #555;
}

/* Responsive Design */
@media (max-width: 768px) {
  .details-flex {
    flex-direction: column;
  }
  .details-grid {
    grid-template-columns: 1fr;
  }
  .address-details {
    border: none;
    padding: 1rem;
  }
}
.back-button-container {
  align-self: flex-start;
  margin: 20px;
}

.back-button {
  background-color: black;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
  width: auto;
}

.back-button:hover {
  background-color: #333;
}