.employees-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0 auto;
  max-width: 800px;
}

.employee-box {
  display: flex;
  align-items: flex-start;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
  gap: 20px;
}

.employee-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  border-color: #007bff;
}

.employee-image {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.employee-box:hover .employee-image {
  transform: scale(1.1);
}

.employee-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  flex: 1;
}

.employee-details p {
  margin: 0;
  font-size: 14px;
}

.employee-details button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  align-self: flex-start;
  margin-top: 10px;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
}

.employee-details button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}
