/* Home.css */

/* Page & Hero Section */
.home-page {
  position: relative;
  overflow-x: hidden;
}

.hero {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

/* Video Wrapper & Vimeo Iframe */
.video-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: black;
  z-index: 1;
}

/* Using the first version to fix the bezel issue */
.vimeo-iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 56.25vw; /* 16:9 aspect ratio */
  min-height: 100vh;
  min-width: 177.77vh; /* 100vh * 16/9 */
  border: none;
  z-index: 1;
}

/* Video Overlay */
.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3); /* from second file */
  padding-top: 80px; /* accounts for navbar height */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 5;
}

/* Video Overlay Text Styles */
.video-overlay h2 {
  font-size: 3rem;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 0.5rem;
}

.video-overlay p {
  font-size: 1.5rem;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 1rem;
}

/* Video Button */
.video-button {
  border: 2px solid #ac53f5;
  background: transparent;
  color: #ac53f5;
  padding: 10px 20px;
  border-radius: 30px;
  font-size: 1.2rem;
  transition: background-color 0.3s ease, color 0.3s ease;
  cursor: pointer;
  display: inline-block;
  width: auto !important;
  white-space: nowrap;
}

.video-button:hover {
  background-color: #ac53f5;
  color: white;
}

/* Navigation */
.nav-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 20;
}

.home-page .custom-nav-header {
  background: transparent !important;
}

/* Home Container & Sections */
.home-container {
  background-color: black;
  color: white;
  min-height: 100vh;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.mission-vision-section,
.vision-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 40px;
}

.image-container img {
  width: 400px;
  height: auto;
  border-radius: 20px;
}

.text-container {
  max-width: 500px;
}

.text-container h1 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.text-container h2 {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.text-container p {
  font-size: 1rem;
  line-height: 1.6;
  margin-top: 0;
}

.content {
  margin-top: 30px;
  text-align: center;
}

.access-token-box {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 8px;
  background-color: #f9f9f9;
  word-wrap: break-word;
  max-width: 100%;
  overflow-x: auto;
  white-space: pre-wrap;
  margin: 10px 0;
}

/* Mobile Responsive Adjustments */
@media (max-width: 768px) {
  .video-overlay h2 {
    font-size: 2rem;
    padding: 0 15px;
  }

  .video-overlay p {
    font-size: 1rem;
    padding: 0 15px;
  }

  .video-button {
    padding: 8px 16px;
    font-size: 1rem;
  }

  .vimeo-iframe {
    width: 177.77vh; /* Maintain 16:9 aspect ratio */
    min-width: 100vw;
    height: 100vh;
  }

  /* Additional mobile adjustments for images and sections */
  .image-container img {
    width: 100%;
    max-width: 300px; /* adjust as needed */
    height: auto;
  }

  .mission-vision-section,
  .vision-section {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .video-overlay h2 {
    font-size: 1.5rem;
  }

  .video-overlay p {
    font-size: 0.9rem;
  }
}