/* New wrapper to override global background */
.page-wrapper {
  background-color: #d3d3d3 !important;
  min-height: 100vh;
}

/* Main container for details */
.details-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 1rem;
}

/* Profile Header */
.profile-header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.employee-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 1rem;
}

.info h2 {
  margin: 0;
  font-size: 1.5rem;
}

/* Details grid */
.details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.field label {
  display: block;
  font-weight: 600;
  margin-bottom: 0.25rem;
}

.field input,
.field select {
  width: 100%;
  padding: 0.4rem;
  margin-bottom: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Availability Section */
.availability-section {
  margin-top: 2rem;
}

/* Availability Grid */
.availability-grid {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1.5rem;
}

.availability-grid th,
.availability-grid td {
  border: 1px solid #ccc;
  text-align: center;
  padding: 0.5rem;
}

.availability-grid thead th {
  background-color: #f1f1f1;
}

/* Highlight covered cells */
.covered-cell {
  background-color: #cce5ff;
  font-weight: bold;
}

/* Add Availability Form */
.add-availability-form {
  background: #f9f9f9;
  padding: 1rem;
  border-radius: 8px;
  margin-top: 1rem;
}

.add-availability-form h4 {
  margin-top: 0;
}

/* Ensure page background is light gray */
body {
  background-color: #d3d3d3;
}
