/* Container for all services */
.services-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 cards per row */
  gap: 16px;
  padding: 16px 32px;
  margin: 0 auto;
  max-width: 1200px;
  box-sizing: border-box;
  background-color: #000;
}

/* Each service card */
.service-card {
  /* Black background, white outline */
  background-color: #000;
  /*border: 1px solid #fff;*/
  border-radius: 8px;

  /* Drop shadow is optional; you can keep or remove */
  box-shadow: 0 4px 6px rgba(255, 255, 255, 0.1);

  padding: 16px;
  text-align: center;
  transition:
    transform 0.3s,
    box-shadow 0.3s;
  height: 350px;
  width: 280px;

  /* Ensure the text is white inside the card */
  color: #fff;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto; /* Center cards in each grid column */
}

/* Hover effect to lift the card slightly */
.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(255, 255, 255, 0.66);
}

/* Image inside the card */
.service-image {
  width: 100%;
  height: 150px;
  object-fit: cover;

  /* Rounded top corners if desired */
  border-radius: 8px 8px 0 0;

  margin-bottom: 16px;
}

/* Service name/title */
.service-name {
  font-size: 1.2rem;
  margin: 8px 0;
  color: #fff; /* ensure text is white */
}

/* Price styling in white */
.service-price {
  font-weight: bold;
  color: #fff;
  margin-bottom: 16px;
}

/* Button for "Book Appointment" */
.service-card button {
  background-color: #000000; /* white background */
  color: #000; /* black text */
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s;
}

.service-card button:hover {
  background-color: #ccc; /* or any hover color you prefer */
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .services-container {
    grid-template-columns: repeat(
      2,
      1fr
    ); /* 2 cards per row on medium screens */
    padding: 16px 24px;
  }
}

@media (max-width: 576px) {
  .services-container {
    grid-template-columns: 1fr; /* 1 card per row on small screens */
    padding: 16px 16px;
  }

  .service-card {
    width: 100%;
  }
}
/* Remove underline from all links inside service cards */
.service-card a {
  text-decoration: none;
  color: inherit; /* Ensures it takes the text color from the parent */
}

/* Remove any hover underline effect */
.service-card a:hover {
  text-decoration: none;
}
body {
  margin: 0;
  padding: 0;
  background-color: black;
}
.service-image2 {
  width: 100%;
  height: 210px;
  object-fit: cover;
  transition: transform 0.3s ease;
  /*height: 470px;*/
}
