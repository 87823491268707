/* General Styles */
body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f9;
  margin: 0;
  padding: 0;
}

h2 {
  text-align: center;
  margin-top: 40px;
  color: #333;
}

p {
  text-align: center;
  margin-bottom: 20px;
  color: #555;
  font-size: 18px;
}

/* Dashboard Layout */
.dashboard-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin: 0 auto;
  max-width: 1200px;
  padding: 20px;
  background-color: #d3d3d3;
}
body {
  background-color: #d3d3d3;
}
.dashboard-page {
  background-color: #d3d3d3 !important;
  min-height: 100vh;
}

.section-container {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto; /* Ensure it grows properly */
  min-height: 200px; /* Minimum height to avoid collapse */
  overflow: auto; /* Allow scrolling if content overflows */
}

.section-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
  color: #007bff;
}

.section-subtitle {
  font-size: 16px;
  margin-bottom: 20px;
  text-align: center;
  color: #666;
}

/* List Styles */
.list-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.list-item {
  display: flex;
  align-items: center;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
}

.list-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  border-color: #007bff;
}

.list-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.list-details button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
}

.list-details button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.list-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 20px;
  transition: transform 0.3s ease;
}

.list-item:hover .list-image {
  transform: scale(1.1);
}

/* Styles for Invite Section */
.invite-section-container {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  max-width: 800px; /* Matches other section widths */
  margin: 0 auto; /* Center alignment */
  position: relative; /* For positioning the close button */
}

/* Generate Employee Invite Button */
.invite-section-container button.generate-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 12px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease,
    box-shadow 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 200px; /* Adjust width */
  text-align: center;
}

.invite-section-container button.generate-button:hover {
  background-color: #0056b3;
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.invite-section-container button.generate-button:active {
  transform: translateY(0);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.invite-section-container button.generate-button:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 91, 187, 0.5);
}

/* Invite Link Container */
.invite-link-container {
  width: 100%;
  text-align: center;
  background-color: #e8f4ff;
  padding: 40px 20px 20px 20px; /* Extra top padding to accommodate close button */
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #cce7ff;
  position: relative; /* To position the close button */
  animation: slideIn 0.3s ease forwards;
}

/* Close Button */
.close-button {
  background-color: #ff4d4d;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.close-button:hover {
  background-color: #ff1a1a;
}

/* Invite Link Text */
.invite-link-text {
  font-size: 16px;
  color: #333;
  margin-bottom: 15px;
  font-weight: 500;
  text-align: center;
}

/* Textarea Styling */
textarea {
  width: 100%;
  height: 80px; /* Adjusted height */
  resize: none;
  border: 1px solid #007bff;
  border-radius: 8px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: #333;
  background-color: #f4f4f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

textarea:focus {
  outline: none;
  border-color: #0056b3;
  box-shadow: 0 0 5px rgba(0, 91, 187, 0.5);
}

/* Invite Link Container Animation */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
