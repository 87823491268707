.custom-nav-header {
  position: relative;
  min-height: 100px;
}

.navbar {
  padding: 1rem 2rem;
  background-color: transparent;
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-left {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.brand-link {
  text-decoration: none;
}

.brand-logo {
  height: 100px;
  width: auto;
  margin-right: 15px;
  max-height: 100px;
}

.nav-right {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.desktop-nav {
  list-style: none;
  display: flex;
  gap: 25px;
  margin: 0;
  padding: 0;
}

.desktop-nav li a {
  color: rgba(240, 240, 240, 0.7);
  text-decoration: none;
  font-weight: 600;
  font-size: 1rem;
  text-transform: uppercase;
  padding: 0.5rem 1rem;
  transition:
    color 0.3s ease,
    background-color 0.3s ease;
}

.desktop-nav li a:hover {
  color: #ac53f5;
  background-color: rgba(0, 0, 0, 0.2);
}

.hamburger-btn {
  display: none;
  flex-direction: column;
  background: transparent;
  border: none;
  cursor: pointer;
  gap: 5px;
}

.hamburger-btn .bar {
  width: 25px;
  height: 3px;
  background-color: #ac53f5;
  transition: transform 0.3s ease;
}

/* Mobile menu */
.nav-links {
  position: fixed;
  top: 0;
  right: -100%;
  width: 250px;
  height: 100vh;
  background-color: #1a1a2e;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.3);
  transition: right 0.3s ease;
  padding: 1rem;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.nav-links.open {
  right: 0;
}

.close-btn {
  background: transparent;
  border: none;
  color: #ac53f5;
  font-size: 1.5rem;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}

.nav-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.nav-links li {
  margin: 1rem 0;
}

.nav-links a {
  color: rgba(240, 240, 240, 0.7);
  text-decoration: none;
  font-weight: 600;
  font-size: 1rem;
  text-transform: uppercase;
  display: block;
  width: 100%;
  padding: 0.5rem 0;
  transition: color 0.3s ease;
}

.nav-links a:hover {
  color: #ac53f5;
}

.btn-signin {
  font-weight: 700;
  font-size: 0.9rem;
  padding: 0.5rem 1.5rem;
  border-radius: 25px;
  border: 2px solid #ac53f5;
  background-color: transparent;
  color: #ac53f5;
  transition:
    background 0.3s ease,
    color 0.3s ease,
    box-shadow 0.3s ease;
}

.btn-signin:hover {
  background-color: #ac53f5;
  color: #1a1a2e;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Responsive: hide desktop nav on small screens, show hamburger */
@media (max-width: 991px) {
  .desktop-nav {
    display: none;
  }
  .hamburger-btn {
    display: flex;
  }
}

@media (max-width: 576px) {
  .navbar {
    padding: 0.5rem 1rem;
  }
  .brand-logo {
    height: 50px;
  }
  .hamburger-btn .bar {
    width: 20px;
  }
  .btn-signin {
    font-size: 0.8rem;
    padding: 0.4rem 1rem;
  }
  .nav-links {
    width: 200px;
  }
}

.language-toggle {
  display: flex;
  gap: 10px;
  align-items: center;
}

.language-btn {
  background: transparent;
  border: 2px solid #ac53f5;
  color: #ac53f5;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 25px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

.language-btn:hover {
  background-color: #ac53f5;
  color: #fff;
}

.language-btn.active {
  background-color: #ac53f5;
  color: #fff;
  border-color: #ac53f5;
}

.language-btn:focus {
  outline: none;
}
