/* Customers Container */
.customers-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0 auto;
  max-width: 800px;
}

/* Customer Box */
.customer-box {
  display: flex;
  align-items: flex-start;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
  gap: 20px;
}

.customer-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  border-color: #007bff;
}

/* Customer Image */
.customer-image {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 12px;
  transition: transform 0.3s ease;
}

.customer-box:hover .customer-image {
  transform: scale(1.05);
}

/* Customer Details */
.customer-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1;
}

.customer-details p {
  margin: 0;
  font-size: 14px;
  color: #555;
}

/* Buttons */
.customer-details button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 10px 14px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  align-self: flex-start;
  margin-top: 10px;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
}

.customer-details button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.no-customers-message {
  text-align: center;
  font-size: 1.2em;
  color: #555;
  margin-top: 20px;
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
  overflow-y: auto;
}

/* Modal */
.modal {
  background: #fff;
  padding: 25px;
  border-radius: 12px;
  width: 100%;
  max-width: 500px;
  max-height: 90%;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  transform: translateY(-20px);
  transition: transform 0.3s ease-in-out;
}

/* Modal Show Transition */
.modal-overlay.show .modal {
  transform: translateY(0);
}

/* Modal Header */
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-header h3 {
  font-size: 1.6rem;
  font-weight: 600;
  color: #333;
}

.close-btn {
  background: #e6e6e6;
  border: none;
  font-size: 20px;
  padding: 6px 12px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.close-btn:hover {
  background-color: #ccc;
}

/* Form Elements */
form div {
  margin-bottom: 15px;
}

form label {
  font-size: 1rem;
  font-weight: 500;
  color: #444;
  margin-bottom: 5px;
  display: block;
}

form input,
form select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1rem;
  color: #333;
  outline: none;
  transition:
    border-color 0.3s ease,
    box-shadow 0.3s ease;
}

form input:focus,
form select:focus {
  border-color: #007bff;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);
}

/* Form Buttons */
form button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 12px 20px;
  font-size: 1rem;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 48%;
  margin-right: 4%;
}

form button:hover {
  background-color: #218838;
}

form button[type="button"] {
  background-color: #f44336;
  /*width: 48%;*/
  margin-right: 0;
}

form button[type="button"]:hover {
  background-color: #d32f2f;
}

/* Responsive Design for Smaller Screens */
@media (max-width: 480px) {
  .modal {
    width: 95%;
    padding: 20px;
  }

  .modal-header h3 {
    font-size: 1.4rem;
  }

  form input,
  form select {
    font-size: 0.9rem;
  }

  form button {
    padding: 10px 15px;
    font-size: 0.9rem;
    width: 100%;
    margin-bottom: 10px;
  }
}
