.service-details-container {
  max-width: 1400px;
  background: white;
  border-radius: 18px;
  padding: 40px;
  margin: 40px auto;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.08);
}

.service-details-top {
  display: flex;
  gap: 50px;
  margin-bottom: 40px;
}

.service-image-wrapper {
  flex: 1;
  min-width: 500px;
  border-radius: 16px;
  overflow: hidden;
  /*box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);*/
}

.service-image {
  width: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
  height: 470px;
}

.service-image:hover {
  transform: scale(1.02);
}

.service-info {
  flex: 1;
  padding: 0 30px;
}

.service-info h1 {
  font-size: 3rem;
  color: #1a1a1a;
  margin-bottom: 20px;
  font-weight: 700;
}

.service-info h2 {
  font-size: 2.5rem;
  color: #007bff;
  margin-bottom: 30px;
  font-weight: 600;
}

.service-description {
  list-style: none;
  padding: 0;
  color: #666;
  font-size: 1.2rem;
  line-height: 1.7;
}

.service-description li {
  padding: 15px 0;
  border-bottom: 1px solid #eee;
}

.separator {
  border: 1px solid #eee;
  margin: 40px 0;
}

.service-scheduler {
  padding-top: 40px;
}

.scheduler-title {
  text-align: center;
  font-size: 2.2rem;
  color: #1a1a1a;
  margin-bottom: 40px;
  padding-bottom: 20px;
  border-bottom: 2px solid #eee;
}

.scheduler-columns {
  display: flex;
  gap: 40px;
  background: #f8f9fa;
  border-radius: 16px;
  padding: 30px;
  margin-bottom: 30px;
}

.datetime-section {
  flex: 1;
  min-width: 400px;
}

.datetime-section label {
  display: block;
  font-size: 1.3rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 25px;
}

.calendar-container {
  background: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.vertical-separator {
  width: 1px;
  background: #ddd;
  margin: 0 20px;
}

.time-slots-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 500px;
  overflow-y: auto;
  padding-right: 15px;
}

/* Base time slot styles */
.time-slot {
  width: 100%;
  padding: 18px;
  background: white;
  border: 2px solid #e0e0e0;
  border-radius: 10px;
  font-size: 1.2rem;
  color: #333;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease;
}

.time-slot:hover {
  border-color: #007bff;
  transform: translateY(-2px);
}

.time-slot.selected {
  background: #007bff;
  color: white;
  border-color: #007bff;
}

/* Updated conflict styles */
.time-slot.red {
  background-color: #ff4d4d; /* Bold red */
  border-color: #ff4d4d;
  cursor: not-allowed;
}

.time-slot.green {
  background-color: #52c41a; /* Bright green */
  border-color: #52c41a;
}

.schedule-button {
  width: 100%;
  padding: 20px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 12px;
  font-size: 1.3rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
}

.schedule-button:hover {
  background: #0069d9;
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(0, 123, 255, 0.3);
}

@media (max-width: 1200px) {
  .service-details-container {
    max-width: 95%;
    padding: 30px;
  }
}

@media (max-width: 992px) {
  .service-details-top {
    flex-direction: column;
    gap: 30px;
  }

  .service-image-wrapper {
    min-width: auto;
  }

  .scheduler-columns {
    flex-direction: column;
    gap: 30px;
    padding: 25px;
  }

  .vertical-separator {
    display: none;
  }

  .datetime-section {
    min-width: auto;
  }

  .time-slots-container {
    height: auto;
    max-height: 400px;
  }
}

@media (max-width: 768px) {
  .service-info h1 {
    font-size: 2.5rem;
  }

  .service-info h2 {
    font-size: 2rem;
  }

  .time-slot {
    padding: 15px;
    font-size: 1.1rem;
    margin-bottom: 5px;
  }

  .schedule-button {
    font-size: 1.1rem;
    padding: 18px;
  }
}
@media (max-width: 768px) {
  .calendar-container {
    display: flex;
    justify-content: center;
    padding: 0;         /* Remove extra padding */
    background: none;   /* Remove background color */
    box-shadow: none;   /* Remove shadow */
  }
}
