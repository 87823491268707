/* General Form Styling */
form {
  /*max-width: 400px;*/
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  font-family: Arial, sans-serif;
}

h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

/* Input Fields */
input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  box-sizing: border-box;
}

input:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Submit Button */
button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
}

/* Responsive Design */
@media (max-width: 600px) {
  form {
    padding: 15px;
  }

  input,
  button {
    font-size: 14px;
  }
}

/*!* General Form Styling *!*/
/*form {*/
/*  max-width: 400px;*/
/*  margin: 50px auto;*/
/*  padding: 20px;*/
/*  border: 1px solid #ccc;*/
/*  border-radius: 10px;*/
/*  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);*/
/*  background-color: #f9f9f9;*/
/*  font-family: Arial, sans-serif;*/
/*}*/

/*h2 {*/
/*  text-align: center;*/
/*  color: #333;*/
/*  margin-bottom: 20px;*/
/*}*/

/*!* Input Fields *!*/
/*input {*/
/*  width: 100%;*/
/*  padding: 10px;*/
/*  margin: 10px 0;*/
/*  border: 1px solid #ccc;*/
/*  border-radius: 5px;*/
/*  font-size: 16px;*/
/*  box-sizing: border-box;*/
/*}*/

/*input:focus {*/
/*  border-color: #007bff;*/
/*  outline: none;*/
/*  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);*/
/*}*/

/*!* Submit Button *!*/
/*button {*/
/*  width: 100%;*/
/*  padding: 10px;*/
/*  background-color: #007bff;*/
/*  color: white;*/
/*  border: none;*/
/*  border-radius: 5px;*/
/*  font-size: 16px;*/
/*  cursor: pointer;*/
/*  margin-top: 10px;*/
/*  transition: background-color 0.3s;*/
/*}*/

/*button:hover {*/
/*  background-color: #0056b3;*/
/*}*/

/*!* Responsive Design *!*/
/*@media (max-width: 600px) {*/
/*  form {*/
/*    padding: 15px;*/
/*  }*/

/*  input,*/
/*  button {*/
/*    font-size: 14px;*/
/*  }*/
/*}*/
